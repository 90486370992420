import { MediaSDK, Props } from './types';
import { VideoPlayerContainer } from './video-player-container';

interface GlobalState extends Partial<Props> {
  mediaSDKInstanceMap: Map<string, () => MediaSDK>;
  renderCountMap: Map<VideoPlayerContainer, number>;
}

type SubscriberCallback = <T>(
  action: string,
  state: GlobalState,
  params?: T
) => void;

class Store {
  private state: GlobalState = {
    mediaSDK: undefined,
    mediaSDKInstanceMap: new Map<string, () => MediaSDK>(),
    tagName: 'video-player',
    renderCountMap: new Map<VideoPlayerContainer, number>,
  };

  private onceMap = new WeakMap();

  private subscribers: SubscriberCallback[] = [];

  public get<K extends keyof GlobalState>(key: K): GlobalState[K] {
    return this.state[key];
  }

  public dispatch(
    action: string,
    newState?: Partial<GlobalState>,
    params?: any
  ) {
    newState && Object.assign(this.state, newState);
    if (action) {
      this.notifySubscribers(action, params);
    }
  }

  public subscribe(callback: SubscriberCallback): () => void {
    this.subscribers.push(callback);
    return () => {
      const index = this.subscribers.indexOf(callback);
      if (index !== -1) {
        this.subscribers.splice(index, 1);
      }
    };
  }

  public once(event: string, callback: SubscriberCallback): void {
    this.onceMap.set(callback, {
      event,
      unSubscribe: this.subscribe(callback),
    });
  }

  public notifySubscribers(action: string, params?: any): void {
    const removeList: (() => void)[] = [];
    this.subscribers.forEach((callback) => {
      callback(action, this.state, params);
      const once = this.onceMap.get(callback);
      if (once) {
        const { event, unSubscribe } = once;
        if (event === action) {
          removeList.push(unSubscribe);
          this.onceMap.delete(callback);
        }
      }
    });
    removeList.forEach((u) => u());
  }
}

export const store = new Store();
