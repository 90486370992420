class WebGLRenderer {
  static VERTEX_SHADER = /* glsl */ `
    attribute vec2 position;
    varying vec2 texCoord;

    void main() {
      texCoord = (position + 1.0) * 0.5;
      // gl_Position = vec4(position, 0.0, 1.0);
      gl_Position = vec4(position.x, -position.y, 0.0, 1.0);
    }
  `;

  static FRAGMENT_SHADER = /* glsl */ `
    precision mediump float;
    varying vec2 texCoord;
    uniform sampler2D videoTexture;

    void main() {
      gl_FragColor = texture2D(videoTexture, texCoord);
    }
  `;

  constructor() {}

  preview(source, target, viewport) {
    const glContext = target.getContext('webgl');

    const program = this.#createShaderProgram(
      glContext,
      WebGLRenderer.VERTEX_SHADER,
      WebGLRenderer.FRAGMENT_SHADER
    );

    const positionAttributeLocation = glContext.getAttribLocation(
      program,
      'position'
    );

    const videoTextureLocation = glContext.getUniformLocation(
      program,
      'videoTexture'
    );

    glContext.useProgram(program);

    const positionBuffer = this.#createBuffer(glContext);
    glContext.bindBuffer(glContext.ARRAY_BUFFER, positionBuffer);
    glContext.enableVertexAttribArray(positionAttributeLocation);
    glContext.vertexAttribPointer(
      positionAttributeLocation,
      2,
      glContext.FLOAT,
      false,
      0,
      0
    );

    this.frame({
      glContext: glContext,
      program: program,
      positionAttributeLocation: positionAttributeLocation,
      textureLocation: videoTextureLocation,
      positionBuffer: positionBuffer,
      viewport: viewport,
      source: source,
    });
  }

  frame(resBundle) {
    const gl = resBundle.glContext;

    resBundle.glContext.clearColor(0.0, 0.0, 0.0, 1.0);
    resBundle.glContext.clear(resBundle.glContext.COLOR_BUFFER_BIT);
    resBundle.glContext.viewport(
      resBundle.viewport.x,
      resBundle.viewport.y,
      resBundle.viewport.w,
      resBundle.viewport.h
    );
    resBundle.glContext.enable(resBundle.glContext.DEPTH_TEST);

    const texture = resBundle.glContext.createTexture();
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.uniform1i(resBundle.textureLocation, 0);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      resBundle.source
    );
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    resBundle.glContext.drawArrays(resBundle.glContext.TRIANGLE_STRIP, 0, 4);
    requestAnimationFrame(() => this.frame(resBundle));
  }

  #createShaderProgram(gl, vertexShaderSource, fragmentShaderSource) {
    const vertexShader = this.#createShader(
      gl,
      gl.VERTEX_SHADER,
      vertexShaderSource
    );
    const fragmentShader = this.#createShader(
      gl,
      gl.FRAGMENT_SHADER,
      fragmentShaderSource
    );

    let program = gl.createProgram();
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);

    return program;
  }

  #createShader(gl, type, source) {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    return shader;
  }

  #createBuffer(gl) {
    const buffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array([-1, -1, 1, -1, -1, 1, 1, 1]),
      gl.STATIC_DRAW
    );
    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    return buffer;
  }
}

export default WebGLRenderer;
