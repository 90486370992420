import { VideoPlayer } from './video-player';
import { VideoPlayerContainer } from './video-player-container';
import { store } from './store';
import { DEFAULT_SESSION_KEY, Props } from './types';
import { randomUUID, addLog } from './utils';

function initVideoPlayer(props: Props) {
  let tagName = 'video-player';
  if (props.tagName) {
    tagName = props.tagName;
    store.dispatch('', { tagName: props.tagName });
    // video player tag name
    addLog(props.mediaSDK?.(), `VPTN:${tagName}`);
  }
  const containerTagName = `${tagName}-container`;
  const uuid = props.sessionId || randomUUID();
  store.get('renderCountMap').clear();
  if (props.mediaSDK) {
    store.get('mediaSDKInstanceMap').set(DEFAULT_SESSION_KEY, props.mediaSDK);
    store.get('mediaSDKInstanceMap').set(uuid, props.mediaSDK);
  }
  if (props.failover) {
    store.dispatch(
      'failover',
      undefined,
      props.sessionId || DEFAULT_SESSION_KEY
    );
  }
  if (!customElements.get(containerTagName)) {
    customElements.define(containerTagName, VideoPlayerContainer);
  }
  if (!customElements.get(tagName)) {
    customElements.define(tagName, VideoPlayer);
  }
  return uuid;
}

export { initVideoPlayer };
