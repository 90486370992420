// 1. only one main task exist at the time
//
// 2. main task and optional task is single case

enum TaskState {
  IDLE = 'IDLE',
  RUNNING = 'RUNNING',
  PENDING = 'PENDING',
}

interface TaskInstance extends ConfigOne, DefaultTaskKey {
  type?: TaskContent;
  taskName?: string;
  task: (v: any) => TaskPromise;
  test?: any;
}

interface DefaultTaskKey {
  state: string;
  taskHandler: TaskPromise | null;
  lastMainTaskResolve: any;
  taskResolve: any;
}

interface TaskPromise extends Promise<any> {
  cancel: Function;
}

const defaultTaskValues: DefaultTaskKey = {
  state: TaskState.IDLE,
  taskHandler: null,
  lastMainTaskResolve: null,
  taskResolve: null,
};
type TaskContent = Function[];

interface ConfigOne {
  current: TaskContent;
  next: ((...a: any) => TaskContent) | TaskContent | TaskContent[];
  isEntry?: boolean;
  isExit?: boolean;
}
type Config = ConfigOne[];
type Env = 'development' | 'production';
export class Task {
  context = {};

  mainTasks: TaskInstance[] = [];

  optionalTasks: TaskInstance[] = [];

  allTasks: TaskInstance[] = [];

  env: Env = 'production';

  private static instance: Task;

  constructor(config: Config, req: any, env: Env) {
    this.allTasks = this.scanAllTasks(req);
    this.mainTaskSchedule(config);
    if (env) {
      this.env = env;
    }
  }

  static init(config: Config, req: any, env: Env) {
    if (!this.instance) {
      this.instance = new Task(config, req, env);
    }
    return this.instance;
  }

  static checkInstance() {
    if (!this.instance) {
      throw new Error('there is no task instance, please init task first');
    }
    return this.instance;
  }

  /**
   *
   * @param taskProps
   * @param optionalTask optional, if it is existing, call optional task, otherwise call main task
     * @param expectMainTask
   * @returns {Promise<T | void>}
   */
  static run({ taskProps, optionalTask, expectMainTask }:
  { taskProps?: any; optionalTask?: TaskContent; expectMainTask?: TaskContent}) {
    const that = this.checkInstance();
    return Promise.resolve()
      .then(() => {
        if (optionalTask) {
          return that.optionalTaskAction(optionalTask, taskProps);
        }
        if (expectMainTask) {
          return that.mainTaskAction(expectMainTask, taskProps);
        }
        return null;
      })
      .catch(e => console.error(e, 'tasks error'));
  }

  static getRunningTasks() {
    const that = this.checkInstance();
    return that.allTasks
      .filter(v => v.state === TaskState.RUNNING)
      .map(v => v.type);
  }

  static getPendingTasks() {
    const that = this.checkInstance();
    return that.allTasks
      .filter(v => v.state === TaskState.PENDING)
      .map(v => v.type);
  }

  static watch(taskType: TaskContent) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const that = this.checkInstance();
        const task = that.findTask(taskType);
        resolve(task.taskHandler);
      }, 0);
    });
  }

  static cancelOptionalTask(optionalTaskType: TaskContent, props: any) {
    const that = this.checkInstance();
    const task = that.validOptionalTask(optionalTaskType);
    return that.cancelTask(task, props);
  }

  /**
   * ctx is used as a Global variable for any task
   * ctx will be passed into task's next, test, task functions as function parameters
   * ctx is allowed to be modified,
   *     but you must be careful to avoid overwriting variables with the same name
   * The main purpose of using ctx is to get the required
   * environmental parameters(global parameters) across tasks
   * @type {{}}
   */
  ctx = (resetProps: any) => {
    if (typeof resetProps === 'object') {
      Object.assign(this.context, resetProps);
    }
    return this.context;
  }

  makeTaskChain = (_task: TaskContent) => {
    if (!(_task && _task.filter(v => v).length > 0)) {
      return (v: any) => Promise.resolve(v);
    }

    return ({ type, taskProps, lastMainTaskResolve }:
    {type: TaskContent; taskProps?: any; lastMainTaskResolve: any}) => {
      let isCanceled = false;
      const preResolves = {};
      const cleanJobs: Function[] = [];
      const cleanJobRegister = (job: Function) => {
        if (typeof job !== 'function') {
          throw new Error(`the clean job must be a function, but it is ${job}`);
        }
        cleanJobs.push(job);
      };
      const promiseChain = _task.reduce((s, func, index) => s.then((lastResolve) => {
        if (isCanceled) {
          return Promise.reject(new Error('canceled'));
        }

        if (index > 0) {
          Object.assign(preResolves, {
            [_task[index - 1].name]: lastResolve,
          });
        }
        /**
         * the item is the one function of the chain of the task.
         */
        return func({
          lastResolve, // resolve of last function
          preResolves, // all resolves of the pre functions, it's a object
          lastMainTaskResolve, // resolve of last task
          taskProps, // the props passed in to the run
          ctx: this.ctx, // the global context can be used for any task model
          type, // the type of current task
          cleanJobRegister, // a function, is used to register clean job, called when canceling task
        });
      }), Promise.resolve()) as TaskPromise;
      promiseChain.cancel = (nextTaskProps: any) => {
        isCanceled = true;
        cleanJobs.forEach(v => v(taskProps, nextTaskProps));
      };
      return promiseChain;
    };
  }

  scanAllTasks = (req: any) => req
    .keys()
    .map((filename: string) => {
      const ret = Object.entries(req(filename));
      if (ret.length > 1) {
        throw new Error(
          `it should only one export function in a task file, please check the file: ${filename}`,
        );
      }
      return ret[0];
    })
    .map(([taskName, taskNode]: [string, TaskContent]) => {
      if (!Array.isArray(taskNode)) {
        throw new Error('task must be an array');
      }
      return {
        type: taskNode,
        taskName,
        task: this.makeTaskChain(taskNode),
        // ...config,
        ...defaultTaskValues,
      };
    })

  mainTaskSchedule = (config: Config) => {
    this.allTasks.forEach((task) => {
      const taskConfig = config.find(v => v.current === task.type);
      if (taskConfig) {
        const { current, ...rest } = taskConfig;
        Object.assign(task, rest);
        this.mainTasks.push(task);
      } else {
        this.optionalTasks.push(task);
      }
    });
  }

  findTask = (taskType: TaskContent) => {
    const task = this.allTasks.find(task => task.type === taskType);
    if (!task) {
      throw new Error(`the task is not exist ${taskType}`);
    }
    return task;
  }

  cancelTask = (task: TaskInstance, props?: any) => {
    if (task.state === TaskState.IDLE) {
      return Promise.resolve();
    }
    // cancel the task first
    if (task.taskHandler) {
      task.taskHandler.cancel(props);
    }
    Object.assign(task, defaultTaskValues);

    return Promise.resolve();
  }

  getCurrentMainTask = () => {
    const currentMainTask = this.mainTasks.filter(
      task => task.state !== TaskState.IDLE,
    );
    if (currentMainTask.length === 1) {
      return currentMainTask[0];
    }
    if (currentMainTask.length === 0) {
      return null;
    }
    throw new Error(
      `there are more than 2 task is running(${currentMainTask
        .map(v => v.taskName)
        .join(',')}), there must be only one main task existing`,
    );
  }

  getEntryTask = () => {
    const entryTask = this.mainTasks.filter(task => task.isEntry);
    if (entryTask.length === 1) {
      return entryTask[0];
    }

    throw new Error(
      'no entry task, there must be only one entry task in the main tasks',
    );
  }

  isMainTask = (type: TaskContent) => !!this.mainTasks.find(v => v.type === type);

  validMainTask = (mainTaskType: TaskContent | undefined) => {
    const task = this.mainTasks.find(v => v.type === mainTaskType);
    if (!mainTaskType || !task) {
      throw new Error(`the mainTaskType is not exist: ${mainTaskType}`);
    }
    return task;
  }

  validOptionalTask = (optionalTaskType: TaskContent) => {
    const task = this.optionalTasks.find(v => v.type === optionalTaskType);
    if (!task) {
      throw new Error(`the optionalTask is not exist: ${optionalTaskType}`);
    }
    return task;
  }

  runTask = ({ nextTaskType, taskProps }: { nextTaskType: TaskContent; taskProps?: any }) => {
    const currentMainTask = this.getCurrentMainTask();
    const isMain = this.isMainTask(nextTaskType);
    const nextTask = this.findTask(nextTaskType);
    if (!currentMainTask && !isMain) {
      return Promise.reject(
        new Error('the main task should be run as the first task'),
      );
    }
    // cancel related tasks before run the task
    const tasks: TaskInstance[] = [];
    tasks.concat(isMain ? this.mainTasks : [nextTask])
      .forEach(v => this.cancelTask(v, taskProps));

    // now, run the task
    if (this.env === 'development') {
      console.log(
        `[task][${isMain ? 'main' : 'optional'}] run: ${nextTask.taskName}`,
      );
    }
    nextTask.state = TaskState.RUNNING;
    if (currentMainTask) {
      nextTask.lastMainTaskResolve = isMain
        ? currentMainTask.taskResolve
        : currentMainTask.lastMainTaskResolve;
    }

    nextTask.taskHandler = nextTask.task({
      type: nextTaskType,
      taskProps,
      lastMainTaskResolve: nextTask.lastMainTaskResolve,
    });
    if (nextTask.taskHandler) {
      nextTask.taskHandler.then((v) => {
        if (nextTask.state === TaskState.RUNNING) {
          nextTask.state = TaskState.PENDING;
          nextTask.taskResolve = v;
        }
      });
    }

    return nextTask.taskHandler;
  }

  optionalTaskAction = (optionalTaskType: TaskContent, taskProps?: any) => {
    this.validOptionalTask(optionalTaskType);
    return this.runTask({
      nextTaskType: optionalTaskType,
      taskProps,
    });
  }

  mainTaskAction = (expectMainTask: TaskContent, taskProps?: any, ) => {
    const currentMainTask = this.getCurrentMainTask();
    let nextTaskType;
    if (!currentMainTask) {
      nextTaskType = this.getEntryTask().type;
    } else {
      const { next, isExit } = currentMainTask;
      if (isExit) {
        return Promise.resolve('no more task now');
      }
      if (!next) {
        return Promise.reject(new Error('no next rule in mainTask'));
      }
      if (typeof next === 'function' ) {
        nextTaskType = next(taskProps, this.ctx);
      } else if (([] as TaskContent[]).concat(next).includes(expectMainTask)) {
        nextTaskType = expectMainTask;
      } else {
        nextTaskType = next;
      }
    }
    this.validMainTask(nextTaskType as TaskContent);
    if (nextTaskType !== expectMainTask) {
      console.error(nextTaskType, expectMainTask);
      return Promise.reject(
        new Error(
          `the next main task [${nextTaskType}] is not the expected [${expectMainTask}].`,
        ),
      );
    }
    return this.runTask({
      nextTaskType: nextTaskType as TaskContent,
      taskProps,
    });
  }
}
