import WebGLRenderer from './webgl_renderer';
import WebGL2Renderer from './webgl2_renderer';
import WebGPURenderer from './webgpu_renderer';

class RenderersProxy {
  constructor() {}

  preview(type, source, target, viewport) {
    let renderer = null;
    if (type == 1) {
      // video-tag
      target.srcObject = source;
    } else if (type == 2) {
      // webgl
      renderer = new WebGLRenderer();
      renderer.preview(source, target, viewport);
    } else if (type == 3) {
      // webgl2
      renderer = new WebGL2Renderer();
      renderer.preview(source, target, viewport);
    } else if (type == 4) {
      // webgpu
      renderer = new WebGPURenderer();
      renderer.preview(source, target, viewport);
    }
  }

  static getInstance() {
    if (!RenderersProxy.instance) {
      RenderersProxy.instance = new RenderersProxy();
    }
    return RenderersProxy.instance;
  }
}

export default RenderersProxy;
