import { CanvasRender } from './canvas-render';
import { VideoRender } from './video-render';
import {
  MediaSDKEvent,
  StartSharing,
  StopSharing,
  WebRTCAddRenderVideo,
  WebRTCStopRenderVideo,
  WasmStopRenderVideo,
  WasmZoomRender,
  WasmAddRenderVideo,
  CanvasAddRenderVideo,
  CanvasZoomRender,
  CanvasStopRenderVideo,
  PreviewAddRenderVideo,
  PreviewStopRenderVideo,
  VideoAddRenderVideo,
  VideoStopRenderVideo,
  SwitchCanvasForVideoCapture,
  VBStartRenderVideo,
  VBStopRenderVideo,
  SingleCanvasAddRenderVideo,
  SingleCanvasStopRenderVideo,
} from '../types';
import { checkRenderNum, updateCurRenderNum } from '../utils';
import { SingleCanvasRender } from './single-canvas-render';

export class WasmVideoRender extends CanvasRender {
  addRender({
    width,
    height,
    x,
    y,
    canvas,
    zone,
    userId,
    left,
    bottom,
    top,
    fillMode,
    videoQuality,
  }: CanvasAddRenderVideo): void {
    if (this.getSDK()) {
      updateCurRenderNum(this.container, true);
      checkRenderNum(this.container);
    }
    this.getSDK()?.Notify_MeidaSDK<WasmAddRenderVideo>(
      MediaSDKEvent.ADD_RENDER_VIDEO,
      {
        width,
        height,
        x,
        y,
        canvas,
        zone,
        userId,
        left,
        bottom,
        top,
        fillMode,
        videoQuality,
      }
    );
  }
  zoomRender({
    width,
    height,
    x,
    y,
    userId,
    canvas,
    RGBA,
    zone,
    left,
    bottom,
    top,
  }: CanvasZoomRender): void {
    this.getSDK()?.Notify_MeidaSDK<WasmZoomRender>(MediaSDKEvent.ZOOM_RENDER, {
      width,
      height,
      x,
      y,
      userId,
      canvas,
      RGBA,
      zone,
      left,
      bottom,
      top,
    });
  }
  stopRender({
    width,
    height,
    x,
    y,
    userId,
    canvas,
    doNotClean,
    RGBA,
    zone,
    left,
    bottom,
    top,
    videoQuality,
  }: CanvasStopRenderVideo): void {
    if (this.getSDK()) {
      updateCurRenderNum(this.container, false);
    }
    this.getSDK()?.Notify_MeidaSDK<WasmStopRenderVideo>(
      MediaSDKEvent.STOP_RENDER_VIDEO,
      {
        width,
        height,
        x,
        y,
        userId,
        canvas,
        doNotClean,
        RGBA,
        zone,
        left,
        bottom,
        top,
        videoQuality,
      }
    );
  }
}

export class WebRTCVideoRender extends VideoRender {
  addRender({ userId, videodom, zone }: VideoAddRenderVideo): void {
    this.getSDK()?.Notify_MeidaSDK<WebRTCAddRenderVideo>(
      MediaSDKEvent.ADD_RENDER_VIDEO,
      {
        userId,
        videodom,
        zone,
      }
    );
  }
  stopRender({ userId, videodom, zone }: VideoStopRenderVideo): void {
    this.getSDK()?.Notify_MeidaSDK<WebRTCStopRenderVideo>(
      MediaSDKEvent.STOP_RENDER_VIDEO,
      {
        userId,
        videodom,
        zone,
      }
    );
  }
}

export class SharingRender extends CanvasRender {
  private getAnnoCanvas() {
    return this.container.getAnnoCanvas();
  }

  addRender({
    width,
    height,
    userId,
    canvas,
    isFromMainSession,
  }: CanvasAddRenderVideo): void {
    this.getSDK()?.Notify_MeidaSDK<StartSharing>(MediaSDKEvent.START_SHARING, {
      width,
      height,
      canvas,
      ssrc: userId,
      isFromMainSession,
      annoCanvas: this.getAnnoCanvas(),
    });
  }
  zoomRender(): void {}
  stopRender({ canvas }: CanvasStopRenderVideo): void {
    this.getSDK()?.Notify_MeidaSDK<StopSharing>(MediaSDKEvent.STOP_SHARING, {
      canvas,
    });
  }
}

export class PreviewVideoRender extends VideoRender {
  addRender({ userId, videodom }: VideoAddRenderVideo): void {
    this.getSDK()?.Notify_MeidaSDK<SwitchCanvasForVideoCapture>(
      MediaSDKEvent.SWITCH_CANVAS_FOR_VIDEO_CAPTURE,
      [<HTMLVideoElement>videodom]
    );
    this.getSDK()?.Notify_MeidaSDK<PreviewAddRenderVideo>(
      MediaSDKEvent.ADD_RENDER_VIDEO,
      {
        userId,
        videodom,
      }
    );
  }
  stopRender({ userId, videodom }: VideoStopRenderVideo): void {
    this.getSDK()?.Notify_MeidaSDK<PreviewStopRenderVideo>(
      MediaSDKEvent.STOP_RENDER_VIDEO,
      {
        userId,
        videodom,
      }
    );
  }
}

export class VBVideoRender extends VideoRender {
  addRender({ id, videodom }: VideoAddRenderVideo): void {
    this.getSDK()?.Notify_MeidaSDK<VBStartRenderVideo>(
      MediaSDKEvent.ADD_VIDEO_VB_SETTING_DOM,
      { id, videodom }
    );
  }
  stopRender({ id, videodom }: VideoStopRenderVideo): void {
    this.getSDK()?.Notify_MeidaSDK<VBStopRenderVideo>(
      MediaSDKEvent.REMOVE_VIDEO_VB_SETTING_DOM,
      { id, videodom }
    );
  }
}

export class VBCanvasRender extends SingleCanvasRender {
  addRender({ id, canvas }: SingleCanvasAddRenderVideo): void {
    this.getSDK()?.Notify_MeidaSDK<VBStartRenderVideo>(
      MediaSDKEvent.ADD_VIDEO_VB_SETTING_DOM,
      { id, canvas }
    );
  }
  stopRender({ id, canvas }: SingleCanvasStopRenderVideo): void {
    this.getSDK()?.Notify_MeidaSDK<VBStopRenderVideo>(
      MediaSDKEvent.REMOVE_VIDEO_VB_SETTING_DOM,
      { id, canvas }
    );
  }
}
