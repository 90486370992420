import {
  CanvasAddRenderVideo,
  CanvasStopRenderVideo,
  CanvasZoomRender,
  InsideRenderType,
  WebRTCAddRenderVideo,
  WebRTCStopRenderVideo,
} from '../types';
import { CanvasRender } from './canvas-render';
import { VideoRender } from './video-render';
import RenderersProxy from './inside/render/renderers_proxy';

interface CanvasRendersProxy {
  preview: (
    type: InsideRenderType,
    source: HTMLVideoElement,
    target: HTMLCanvasElement,
    viewport?: {
      x: number;
      y: number;
      w: number;
      h: number;
    }
  ) => void;
}

interface VideoTagRendersProxy {
  preview: (
    type: InsideRenderType.VideoTag,
    source: MediaProvider,
    target: HTMLVideoElement
  ) => void;
}

export class InsideCanvasRender extends CanvasRender {
  private rendersProxy: CanvasRendersProxy = RenderersProxy.getInstance();
  private videoSource: HTMLVideoElement | null = null;

  private async createSource(
    stream: MediaProvider,
    width: number,
    height: number
  ) {
    // create a video element as the source seeding to a canvas for rendering
    const video = document.createElement('video');
    video.width = width;
    video.height = height;
    video.loop = true;
    video.autoplay = true;
    video.muted = true;
    video.srcObject = stream;
    await video.play();
    return video;
  }

  protected addRender(p: CanvasAddRenderVideo): void {
    if (p.renderType && p.srcObject && !this.videoSource) {
      if (this.videoSource) this.videoSource = null;
      this.createSource(p.srcObject, p.width, p.height).then((video) => {
        this.videoSource = video;
        this.rendersProxy.preview(p.renderType!, this.videoSource, p.canvas, {
          x: p.x,
          y: p.renderType === InsideRenderType.WebGPU ? p.top : p.y,
          w: p.width,
          h: p.height,
        });
      });
    }
  }
  protected zoomRender(p: CanvasZoomRender): void {
    if (p.renderType && this.videoSource) {
      if (this.videoSource.width !== p.width) {
        this.videoSource.width = p.width;
      }
      if (this.videoSource.height !== p.height) {
        this.videoSource.height = p.height;
      }
      // this.rendersProxy.preview(p.renderType, this.videoSource, p.canvas, {
      //   x: p.x,
      //   y: p.y,
      //   w: p.width,
      //   h: p.height,
      // });
    }
  }
  protected stopRender(p: CanvasStopRenderVideo): void {
    if (p.renderType && this.videoSource) {
      this.videoSource.srcObject = null;
      // this.rendersProxy.preview(p.renderType, this.videoSource, p.canvas, {
      //   x: p.x,
      //   y: p.y,
      //   w: p.width,
      //   h: p.height,
      // });

      this.videoSource = null;
    }
  }
}

export class InsideVideoTagRender extends VideoRender {
  private rendersProxy: VideoTagRendersProxy = RenderersProxy.getInstance();
  protected addRender(p: WebRTCAddRenderVideo): void {
    if (p.srcObject && p.videodom) {
      this.rendersProxy.preview(
        InsideRenderType.VideoTag,
        p.srcObject,
        <HTMLVideoElement>p.videodom
      );
    }
  }
  protected stopRender(p: WebRTCStopRenderVideo): void {
    (<HTMLVideoElement>p.videodom).srcObject = null;
  }
}
