declare global {
  var JsMediaSDK_Instance: {
    util?: {
      getMaxCountRender?: () => number;
    };
  };
}

export enum MediaSDKEvent {
  ADD_RENDER_VIDEO = 1,
  STOP_RENDER_VIDEO = 2,
  START_SHARING = 23,
  STOP_SHARING = 24,
  SWITCH_CANVAS_FOR_VIDEO_CAPTURE = 25,
  UPDATE_SHARING_DECODE_PARAM = 44,
  UPDATE_CANVAS_SIZE = 46,
  ZOOM_RENDER = 48,
  REMOVE_EXPIRED_CANVAS = 76,
  WEBGL_LOST_REPLACE_CANVAS = 77,
  UPDATE_VIDEO_QUALITY = 78,
  SEND_RENDER_LOG = 79,
  ADD_VIDEO_VB_SETTING_DOM = 102,
  REMOVE_VIDEO_VB_SETTING_DOM = 103,
}

export enum MediaSDKNotify {
  WEBGL_LOST_IN_MULTI_VIEW = -32,
  MOBILE_CAPTURE_DEVICE_CHANGE = 135,
}

export const DEFAULT_SESSION_KEY = 'default-sesssion-key';

export enum MediaType {
  Video = 'video',
  Share = 'share',
  Preview = 'preview',
}

export enum ShareSource {
  DEFAULT = '',
  MAIN = 'main',
}

export type RGBA = {
  R: number;
  G: number;
  B: number;
  A: number;
};

export type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
  left: number;
  bottom: number;
  top: number;
};

export type MediaSDK = {
  getWebRTCFlag(): boolean;
  isPreviewVideotag(userId: string): boolean;
  isSelfUser(userId: string): boolean;
  Notify_MeidaSDK: <T>(event: MediaSDKEvent, param: Partial<T>) => void;
  addCallback: (callback: (type: MediaSDKNotify, data: any) => void) => void;
  removeCallback: (callback: (type: MediaSDKNotify, data: any) => void) => void;
};

export type WebGLLostInMultiView = {
  canvasId: string;
  replaceCanvas: boolean;
};

export type RemoveExpiredCanvas = {
  canvasId: string;
};

export type WebGLLostReplaceCanvas = {
  canvasId: string;
  canvas: HTMLCanvasElement;
};

export interface Props {
  mediaSDK?: () => MediaSDK;
  tagName?: string;
  sessionId?: string;
  failover?: string;
}

export interface RenderPosition {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface WasmAddRenderVideo {
  userId: number;
  x: number;
  y: number;
  width: number;
  height: number;
  canvas: HTMLCanvasElement;
  zone?: string;
  left: number;
  bottom: number;
  top: number;
  fillMode?: boolean;
  videoQuality: string | null;
}

export interface WasmStopRenderVideo {
  userId: number;
  x: number;
  y: number;
  width: number;
  height: number;
  canvas: HTMLCanvasElement;
  zone?: string;
  doNotClean: boolean;
  RGBA: RGBA;
  left: number;
  bottom: number;
  top: number;
  videoQuality: string | null;
}

export interface WasmZoomRender {
  userId: number;
  x: number;
  y: number;
  width: number;
  height: number;
  canvas: HTMLCanvasElement;
  zone?: string;
  RGBA?: RGBA;
  left: number;
  bottom: number;
  top: number;
}

export interface WebRTCAddRenderVideo extends InsideRenderParams {
  userId: number;
  videodom?: HTMLVideoElement;
  zone?: string;
}

export interface WebRTCStopRenderVideo extends InsideRenderParams {
  userId: number;
  videodom?: HTMLVideoElement;
  zone?: string;
}

export interface PreviewAddRenderVideo {
  userId: number;
  videodom?: HTMLVideoElement;
}

export type SwitchCanvasForVideoCapture = HTMLVideoElement | HTMLVideoElement[];

export interface PreviewStopRenderVideo {
  userId: number;
  videodom?: HTMLVideoElement;
}

export interface VBStartRenderVideo {
  id: string;
  canvas?: HTMLCanvasElement;
  videodom?: HTMLVideoElement;
}

export interface VBStopRenderVideo {
  id: string;
  canvas?: HTMLCanvasElement;
  videodom?: HTMLVideoElement;
}

export interface StartSharing {
  width: number;
  height: number;
  ssrc: number;
  canvas: HTMLCanvasElement;
  isFromMainSession?: boolean;
  annoCanvas: HTMLCanvasElement;
  // cursorCanvas,
  // enableWaterMark,
  // waterMarkText,
  // isFromMainSession: isReceiveSharingFromMainSession,
  // watermarkOpacity: watermarkOpacityLevel,
  // watermarkRepeated: watermarkCoverType === WATERMARK_COVER_TYPE.REPEATED,
  // watermarkPosition,
}

export interface StopSharing {
  canvas: HTMLCanvasElement;
}

export interface InsideRenderParams {
  srcObject?: MediaProvider | null;
  renderType?: InsideRenderType;
}

export interface CanvasAddRenderVideo
  extends WasmAddRenderVideo,
    Omit<StartSharing, 'annoCanvas'>,
    InsideRenderParams {}

export interface CanvasStopRenderVideo
  extends WasmStopRenderVideo,
    StopSharing,
    InsideRenderParams {}

export interface CanvasZoomRender extends WasmZoomRender, InsideRenderParams {}

export interface VideoAddRenderVideo
  extends WebRTCAddRenderVideo,
    PreviewAddRenderVideo,
    InsideRenderParams,
    VBStartRenderVideo {}

export interface VideoStopRenderVideo
  extends WebRTCStopRenderVideo,
    PreviewStopRenderVideo,
    InsideRenderParams,
    VBStopRenderVideo {}

export interface SingleCanvasAddRenderVideo extends VBStartRenderVideo {}

export interface SingleCanvasStopRenderVideo extends VBStopRenderVideo {}

export interface RenderParams {
  fillMode: boolean;
  doNotClean: boolean;
  isFromMainSession: boolean;
  videoQuality: string | null;
  renderType?: InsideRenderType;
}

export enum VideoQuality {
  Video_90P = '0',
  Video_180P = '1',
  Video_360P = '2',
  Video_720P = '3',
  Video_1080P = '4',
}

export interface UpdateVideoQuality {
  userId: string;
  videoQuality: string;
}

export enum InsideRenderType {
  Unknown = 0,
  VideoTag = 1,
  WebGL = 2,
  WebGL2 = 3,
  WebGPU = 4,
}

export enum ResizeStrategy {
  Debounce = 'debounce',
  Throttle = 'throttle',
  None = 'none',
}
