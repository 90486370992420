import {
  DOMRectPick,
  getRelativeRect,
  observePosition,
} from '../observe-position';
import { store } from '../store';
import {
  CanvasAddRenderVideo,
  CanvasStopRenderVideo,
  CanvasZoomRender,
  MediaSDKEvent,
  Position,
  RenderParams,
  UpdateVideoQuality,
} from '../types';
import {
  debounce,
  getMediaSDK,
  objectsAreEqual,
  randomUUID,
  addLog,
  getScale,
} from '../utils';
import { VideoPlayerContainer } from '../video-player-container';
import { Render } from './base';

export abstract class CanvasRender implements Render {
  private el: HTMLDivElement = document.createElement('div');
  private position: Position | null = null;
  protected container: VideoPlayerContainer;
  private id = '';
  private srcObject: MediaProvider | null = null;
  private removePositionObserve: (() => void) | null = null;
  private removeSubscribe?: () => void;
  private viewId = `${store.get('tagName')}-${randomUUID()}`;

  protected getSDK = () => getMediaSDK(this.container?.getSessionId?.());

  private debounceUpdateVideoPosition: (rect?: DOMRectPick) => void;

  constructor(
    container: VideoPlayerContainer | null,
    private getRenderParams: () => RenderParams
  ) {
    if (!container) {
      throw new Error(
        `The ${store.get('tagName')} must have a ${store.get(
          'tagName'
        )}-container as its ancestor element.`
      );
    }
    this.container = container;
    this.el.style.width = '100%';
    this.el.style.height = '100%';

    this.debounceUpdateVideoPosition = debounce(
      this.updateVideoPosition.bind(this),
      300
    );
  }

  getElement(): HTMLDivElement {
    return this.el;
  }

  init() {
    this.removeSubscribe = store.subscribe((e, state, p) => {
      if (p === this.getCanvas().id) {
        if (e === 'resize') {
          this.updateVideoPosition();
          // For low-end devices, add delay to makesure the layout finish
          setTimeout(() => {
            this.updateVideoPosition();
          }, 300);
        } else if (e === 'scale') {
          addLog(this.getSDK(), `VPSC:${getScale(this.getCanvas())}`);
        }
      }
    });
  }

  private getCanvas() {
    return this.container.getCanvas();
  }

  private initObservePosition() {
    this.stopObservePosition();
    this.removePositionObserve = observePosition(
      this.el,
      (data, force) => {
        if (!force) {
          this.debounceUpdateVideoPosition(data);
        } else {
          setTimeout(() => {
            this.updateVideoPosition();
          }, 300);
        }
      },
      { wrapper: this.container }
    );
  }

  private stopObservePosition() {
    if (this.removePositionObserve) {
      this.removePositionObserve();
      this.removePositionObserve = null;
    }
  }

  private getCurrentPosition(dom: HTMLElement) {
    return this.transformRectToPosition(getRelativeRect(dom, this.container));
  }

  private transformRectToPosition(rect: DOMRectPick) {
    const { left, width, height, bottom, top } = rect;
    const scale = getScale(this.getCanvas());
    return {
      x: left * scale,
      y: bottom * scale,
      width: width * scale,
      height: height * scale,
      left: left * scale,
      bottom: bottom * scale,
      top: top * scale,
    };
  }

  public playVideo(source: string | MediaProvider) {
    this.initObservePosition();
    if (typeof source === 'string') {
      this.id = source;
    } else {
      this.srcObject = source;
    }
    const position = this.getCurrentPosition(this.el);
    this.addRender({
      ...position,
      canvas: this.getCanvas(),
      zone: this.viewId,
      userId: Number(this.id),
      ssrc: Number(this.id),
      ...this.getRenderParams(),
      ...(!this.srcObject ? {} : { srcObject: this.srcObject }),
    });
    this.position = position;
  }

  private updateVideoPosition(rect?: DOMRectPick) {
    if (!this.id && !this.srcObject) return;
    const position = this.transformRectToPosition(
      rect || getRelativeRect(this.el, this.container)
    );
    if (this.position && objectsAreEqual(position, this.position)) {
      return;
    }
    this.zoomRender({
      ...position,
      userId: Number(this.id),
      canvas: this.getCanvas(),
      RGBA: this.container.getRGBA(),
      zone: this.viewId,
      ...this.getRenderParams(),
      ...(!this.srcObject ? {} : { srcObject: this.srcObject }),
    });
    this.position = position;
  }

  public stopVideo() {
    this.stopObservePosition();
    if ((!this.id && !this.srcObject) || !this.position) return;
    if (this.id || this.srcObject) {
      this.stopRender({
        ...this.position,
        userId: Number(this.id),
        canvas: this.getCanvas(),
        RGBA: this.container.getRGBA(),
        zone: this.viewId,
        ...this.getRenderParams(),
        ...(!this.srcObject ? {} : { srcObject: this.srcObject }),
      });
    }
    this.position = null;
    this.id = '';
    this.srcObject = null;
  }

  public updateVideoQuality(quality: string): void {
    if (!this.id) {
      return;
    }
    this.getSDK()?.Notify_MeidaSDK<UpdateVideoQuality>(
      MediaSDKEvent.UPDATE_VIDEO_QUALITY,
      {
        userId: this.id,
        videoQuality: quality,
      }
    );
  }

  public refresh(): void {
    this.debounceUpdateVideoPosition();
  }

  public destroy() {
    this.stopVideo();
    this.removeSubscribe?.();
  }

  protected abstract addRender(p: CanvasAddRenderVideo): void;
  protected abstract zoomRender(p: CanvasZoomRender): void;
  protected abstract stopRender(p: CanvasStopRenderVideo): void;
}
