function logTime(isShowDate?: boolean) {
  const date = new Date();
  let time = '';
  if (isShowDate) {
    time += `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} `;
  }
  return `[${time}${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}]:\n`;
}

function logInfo(serverList: Server[]) {
  return serverList.map((server) => ({
    domain: server.domain,
    dl: server.dl,
    dc: server.dc,
  }));
}

/**
 * connect the server and get the fast response with some strategy
 * @input server list
 * server list:
 * [
 *    {url: '' , domain: '', rwcToken: '', dl: 0, dc: 'CN'},
 *    {url: '' , domain: '', rwcToken: '', dl: 0, dc: 'CN'},
 *    {url: '' , domain: '', rwcToken: '', dl: 3000, dc: 'AP'},
 *    {url: '' , domain: '', rwcToken: '', dl: 3000, dc: 'AP'},
 *    {url: '' , domain: '', rwcToken: '', dl: 5000, dc: 'US'},
 *    {url: '' , domain: '', rwcToken: '', dl: 5000, dc: 'US'},
 * ]
 * @output fast server response
 * @usage available interface
 * ConnectService.ping
 * ConnectService.retry
 */

interface Server {
  url: string;
  domain: string;
  dl: number;
  dc: string;
  rwcToken?: string;
  exclude?: boolean;
}

const groupedByDl = (data: Server[]) => {
  const group = data.reduce((groupByDelayTime, item) => {
    const key = item.dl;
    if (!groupByDelayTime[key]) {
      groupByDelayTime[key] = [];
    }
    groupByDelayTime[key].push(item);
    return groupByDelayTime;
  }, {} as any);
  return Object.entries(group)
      .map(([key, v]) => [Number(key) ?? 0, v] as [number, any])
      .sort((a, b) => a[0] - b[0])
      .map(([, v]) => v);
};

/**
 * connect the server with the same delay time
 * [
 *    {url: '' , domain: '', rwcToken: '', dl: 0, dc: 'CN'},
 *    {url: '' , domain: '', rwcToken: '', dl: 0, dc: 'CN'},
 * ]
 */
const makeRWCRequestForGroup = (
    currentGroup: Server[],
    signal: AbortSignal | undefined,
) => {
  const option: RequestInit = { mode: 'cors', cache: 'no-cache' };
  if (signal) {
    Object.assign(option, { signal });
  }
  return Promise.any(
      currentGroup.map(async (server: Server) => {
        try {
          const response = await fetch(server.url, option);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response
              .json()
              .then((data) => {
                const callId = response.headers.get('call-id');
                return {
                  response: data,
                  server,
                  callId,
                };
              })
              .catch(() => ({
                response: {},
                server,
                callId: '',
              }));
        } catch (error: any) {
          const msg = `server: ${server.domain}, message: ${error.message}`;
          throw new Error(msg);
        }
      }),
  ).catch((error) => {
    let msg = '';
    if (error instanceof AggregateError) {
      error.errors.forEach((err) => {
        msg += `${err.message}\n`;
      });
    }
    return Promise.reject(msg);
  });
};

function cancellableTimeoutPromise(
    promise: () => Promise<any>,
    timeout: number,
    signal: AbortSignal | undefined,
) {
  return new Promise((resolve, reject) => {
    // If the signal is already aborted, reject immediately
    if (signal?.aborted) {
      return reject(new Error('Promise was cancelled'));
    }
    const handler = setTimeout(() => {
      promise()
          .then(resolve)
          .catch(reject)
          .finally(() => signal?.removeEventListener?.('abort', onAbort));
    }, timeout);

    const onAbort = () => {
      clearTimeout(handler);
      signal?.removeEventListener?.('abort', onAbort);
      reject(new Error('Promise was cancelled'));
    };

    signal?.addEventListener?.('abort', onAbort);
  });
}

class ConnectService {
  /**
   * @description input server list
   */
  private serverList: Server[] = [];

  /**
   * @description delay time of each group like [0, 3000, 5000]
   * split list into some groups
   */
  private delayTime: number[] = [];
  /**
   * @description excluded server
   */
  private excludedServer: Server[] = [];

  /**
   * @description the fast returned server may be used in filter logic when retry
   */
  private lastServer?: Server;

  private log: string = '';

  private tryTimes: number = 0;

  private tryPingIndex: number = 0;
  private MAX_RETRY_TIMES = 5;
  private abortPingController: (AbortController | undefined)[] = [];
  setRetryTimes(times: number = 5) {
    this.MAX_RETRY_TIMES = times;
  }

  private init(serverList: Server[]) {
    this.serverList = serverList;
    this.delayTime = Array.from(
        new Set(this.serverList.map((item) => item.dl)),
    );
    this.excludedServer = [];
    this.tryTimes = 1;
    this.log = `${logTime()}initial server list: ${JSON.stringify(
        logInfo(this.serverList),
    )}\n`;
  }

  /**
   * @param serverList
   * @returns { response, request, log }
   */
  ping(serverList: Server[]) {
    this.init(serverList);
    return this.tryPing();
  }

  /**
   * retry servers, if the last server is bad, or should use ping instead
   * @returns { response, request, log }
   */
  retry() {
    // retry means the last server is bad, ping after filter the bad servers.
    this.tryTimes += 1;
    this.filter();
    if (
        this.serverList.length === 0 ||
        (this.MAX_RETRY_TIMES > 0 && this.tryTimes > this.MAX_RETRY_TIMES)
    ) {
      return Promise.reject(`no usable RWC any more:\n${this.log}`);
    }
    return this.tryPing();
  }

  private makeAbortSignal = (index: number) => {
    if (typeof AbortController === 'function') {
      this.abortPingController[index] = new AbortController();
      return this.abortPingController[index]?.signal;
    }
    return undefined;
  };

  cancelPing(index: number): void {
    // cancel the all request
    if (this.abortPingController[index]) {
      this.abortPingController[index]?.abort?.();
      this.abortPingController[index] = undefined;
    }
  }

  private tryPing() {
    const currentTryPingIndex = this.tryPingIndex++;
    this.cancelPing(currentTryPingIndex - 1);
    this.log += `${logTime(true)}Try Times: ${this.tryTimes}\n`;

    const signal = this.makeAbortSignal(currentTryPingIndex);
    return new Promise((resolve, reject) => {
      const sum = this.delayTime.reduce((s, v) => s + v, 0);
      const pingTimeout = setTimeout(() => {
        this.log += `ping time out ${sum + 10000}s\n`;
        this.serverList = [];
        this.cancelPing(currentTryPingIndex);
      }, sum + 10000);
      const groups = groupedByDl(this.serverList);
      Promise.any(
          groups.map((currentGroup: Server[], index) => {
            const time = this.delayTime[index];
            return cancellableTimeoutPromise(
                () => {
                  this.log += `${logTime()}ping[dl=${time}][groupIndex=${index}]-->${JSON.stringify(
                      currentGroup.map((v) => v.domain),
                  )}\n`;
                  return makeRWCRequestForGroup(currentGroup, signal);
                },
                time,
                signal,
            );
          }),
      )
          .then(({ response, server, callId }: any) => {
            this.log += `${logTime()}ping resolved: request server:${
                server.domain
            }\n response:${JSON.stringify(response)}\n Call-ID:${callId}\n`;
            this.lastServer = server;
            resolve({
              response,
              request: server,
              log: this.log,
            });
          })
          .catch((error) => {
            if (error instanceof AggregateError) {
              this.log += logTime();
              error.errors.forEach((message, index) => {
                this.log += `ping failed [groupIndex=${index}]:\n${message}\n`;
              });
            } else {
              this.log += `${logTime()}ping failed. Errors(unknown): ${
                  error.message
              }\n`;
            }
            return reject(this.log);
          })
          .finally(() => {
            clearTimeout(pingTimeout);
            this.cancelPing(currentTryPingIndex);
          });
    });
  }

  private filter(): void {
    let isBadDataCenter: string | undefined;
    this.serverList = this.serverList.filter((server) => {
      if (
          server.domain === this.lastServer?.domain ||
          server.dc === isBadDataCenter
      ) {
        if (!isBadDataCenter) {
          isBadDataCenter = this.excludedServer.find(
              (excluded) => excluded.dc === this.lastServer?.dc,
          )?.dc;
        }
        if (this.lastServer) {
          this.excludedServer.push(this.lastServer);
        }
        return false;
      } else {
        return true;
      }
    });

    this.log += `${logTime()}excludedServer ${JSON.stringify(
        this.excludedServer.map((v) => v.domain),
    )}\n`;
  }

  getLog() {
    return this.log;
  }
}

export default new ConnectService();
