import {
  MediaSDKEvent,
  RemoveExpiredCanvas,
  SingleCanvasAddRenderVideo,
  SingleCanvasStopRenderVideo,
} from '../types';
import { getMediaSDK, randomUUID } from '../utils';
import { VideoPlayerContainer } from '../video-player-container';
import { Render } from './base';

export abstract class SingleCanvasRender implements Render {
  private el: HTMLCanvasElement;
  private id: string = '';
  private srcObject: MediaProvider | null = null;

  protected getSDK = () => getMediaSDK(this.container?.getSessionId?.());

  private resizeObserver = new ResizeObserver(([e]) => {
    try {
      this.el.width = e.target.clientWidth;
      this.el.height = e.target.clientHeight;
    } catch {}
  });

  constructor(private container: VideoPlayerContainer | null) {
    this.el = document.createElement('canvas');
    this.el.id = randomUUID();
    this.el.style.borderRadius = 'inherit';
    this.el.style.width = '100%';
    this.el.style.height = '100%';
  }

  getElement(): HTMLCanvasElement {
    return this.el;
  }

  init() {
    if (this.el.parentElement) {
      this.el.width = this.el.parentElement.clientWidth;
      this.el.height = this.el.parentElement.clientHeight;
      this.resizeObserver.observe(this.el.parentElement);
    }
  }

  public playVideo(source: string | MediaProvider) {
    if (typeof source === 'string') {
      this.id = source;
    } else {
      this.srcObject = source;
    }
    this.addRender({
      id: this.id,
      canvas: this.el,
    });
  }

  public stopVideo() {
    if (!this.id && !this.srcObject) return;
    this.stopRender({
      id: this.id,
      canvas: this.el,
    });
    this.id = '';
    this.srcObject = null;
  }

  public updateVideoQuality(quality: string): void {
    return;
  }

  public destroy() {
    this.stopVideo();
    this.resizeObserver.disconnect();
    getMediaSDK(
      this.container?.getSessionId?.()
    )?.Notify_MeidaSDK<RemoveExpiredCanvas>(
      MediaSDKEvent.REMOVE_EXPIRED_CANVAS,
      { canvasId: this.el.id }
    );
  }

  protected abstract addRender(p: SingleCanvasAddRenderVideo): void;
  protected abstract stopRender(p: SingleCanvasStopRenderVideo): void;
}
