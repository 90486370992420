import { debounce } from './utils';

export function getRelativeRect(
  target: HTMLElement,
  container: HTMLElement
): DOMRectPick {
  const { left, top, bottom, right, width, height } =
    target.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  return {
    left: left - containerRect.left,
    top: top - containerRect.top,
    bottom: containerRect.bottom - bottom,
    right: containerRect.right - right,
    width,
    height,
  };
}

export type DOMRectPick = Pick<
  DOMRect,
  'width' | 'height' | 'top' | 'left' | 'bottom' | 'right'
>;

interface Options {
  wrapper?: HTMLElement;
}

interface Pos extends DOMRectPick {
  hide: boolean;
  cross: boolean;
}

type Callback = (pos: Pos, force?: boolean) => void;

const thresholdList: number[] = [];
for (let i = 0; i <= 1.0; i += 0.01) {
  thresholdList.push(i);
}

const isHide = (
  t: number,
  b: number,
  l: number,
  r: number,
  w: number,
  h: number
) =>
  (t <= 0 && t + h <= 0) ||
  (b <= 0 && b + h <= 0) ||
  (l <= 0 && l + w <= 0) ||
  (r <= 0 && r + w <= 0);
const isCover = (t: number, b: number, l: number, r: number) =>
  t < 0 || b < 0 || l < 0 || r < 0;

export function observePosition(
  el: HTMLElement,
  callback: Callback,
  options: Options = {}
) {
  const { wrapper = document.documentElement } = options;

  const forceCallback = debounce(callback, 300);

  // const viz = document.createElement("div");
  // viz.className = "viz";
  // viz.style.backgroundColor = "rgba(10, 10, 10, .5)";
  // viz.style.pointerEvents = "none";
  // viz.style.position = "absolute";
  // wrapper.append(viz);

  let intersectionObserver: IntersectionObserver | null;
  let resizeObserver: ResizeObserver | null;

  const obsCallback = (threshold = 1.0, disappear = false) => {
    if (intersectionObserver) {
      intersectionObserver.disconnect();
      intersectionObserver = null;
    }

    const { top, left, width, height, right, bottom } = getRelativeRect(
      el,
      wrapper
    );

    if (!width || !height) {
      callback({
        hide: true,
        cross: false,
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      });
      return;
    }

    const cover = isCover(top, bottom, left, right);
    const hide = isHide(top, bottom, left, right, width, height);

    const options: IntersectionObserverInit = {
      root: wrapper,
      threshold: cover ? thresholdList : threshold,
    };
    if (!cover) {
      options.rootMargin = `${-top}px ${-right}px ${-bottom}px ${-left}px`;
    }

    let isFirstUpdate = true;
    let isFirstHide = true;

    if (disappear) {
      isFirstHide = false;
    }

    callback({ hide, cross: cover, top, left, width, height, bottom, right });

    forceCallback(
      { hide, cross: cover, top, left, width, height, bottom, right },
      true
    );

    intersectionObserver = new IntersectionObserver(([entry]) => {
      if (entry.intersectionRatio === 0) {
        if (isFirstHide) {
          return obsCallback(1.0, true);
        }
        return;
      }
      // const { top, left, bottom, right, width, height } = entry.rootBounds!;
      // viz.style.width = `${width}px`;
      // viz.style.height = `${height}px`;
      // viz.style.top = `${top}px`;
      // viz.style.right = `${right}px`;
      // viz.style.bottom = `${bottom}px`;
      // viz.style.left = `${left}px`;
      if (threshold !== entry.intersectionRatio) {
        if (!isFirstUpdate) {
          return obsCallback();
        }
        obsCallback(
          entry.intersectionRatio === 0.0 ? 0.0000001 : entry.intersectionRatio
        );
      }
      if (entry.intersectionRatio === 1.0 && cover) {
        obsCallback();
      }
      isFirstUpdate = false;
    }, options);
    intersectionObserver.observe(el);
  };
  obsCallback();
  resizeObserver = new ResizeObserver(() => obsCallback());
  resizeObserver.observe(el);
  return () => {
    intersectionObserver?.disconnect();
    intersectionObserver = null;
    resizeObserver?.disconnect();
    resizeObserver = null;
  };
}
