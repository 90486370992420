import { store } from './store';
import {
  DEFAULT_SESSION_KEY,
  MediaSDK,
  MediaSDKEvent,
  ResizeStrategy,
} from './types';
import { VideoPlayerContainer } from './video-player-container';

type Color = {
  R: number;
  G: number;
  B: number;
  A: number;
};

export function getColorValue(color: string): Color {
  let r = 0,
    g = 0,
    b = 0,
    a = 0;
  if (color[0] === 'r') {
    if (color[3] === 'a') {
      const rgba = color
        .slice(5, -1)
        .split(',')
        .map((i) => parseInt(i.trim(), 10));
      r = rgba[0];
      g = rgba[1];
      b = rgba[2];
      a = rgba[3];
    } else {
      const rgb = color
        .slice(4, -1)
        .split(',')
        .map((i) => parseInt(i.trim(), 10));
      r = rgb[0];
      g = rgb[1];
      b = rgb[2];
    }
  } else if (color[0] === '#') {
    let colorStr = color.slice(1);
    if (colorStr.length === 3) {
      colorStr = colorStr
        .split('')
        .map((c) => c.repeat(2))
        .join('');
    }
    const colorVal = parseInt(colorStr, 16);
    r = (colorVal >> 16) & 0xff;
    g = (colorVal >> 8) & 0xff;
    b = colorVal & 0xff;
  }
  return {
    R: r / 255.0,
    G: g / 255.0,
    B: b / 255.0,
    A: a,
  };
}

export function createCanvas(
  width: number,
  height: number,
  zIndex: string,
  id: string,
  sendLog: (s: string) => void,
  visiable: boolean = false
) {
  const canvas = document.createElement('canvas');
  canvas.id = id;
  const scale = updateScale(canvas, width, height);
  sendLog(`VPISC:${window.devicePixelRatio},${scale}`);
  canvas.style.position = 'absolute';
  canvas.style.left = '0px';
  canvas.style.top = '0px';
  canvas.style.width = width + 'px';
  canvas.width = width * getScale(canvas);
  canvas.style.height = height + 'px';
  canvas.height = height * getScale(canvas);
  canvas.style.zIndex = zIndex;
  if (!visiable) {
    canvas.style.pointerEvents = 'none';
    canvas.ariaHidden = 'true';
  }
  return canvas;
}

export function addCanvasEventListener(
  canvas: HTMLCanvasElement,
  container: VideoPlayerContainer
) {
  const handleResize = () => {
    if (!canvas) {
      return;
    }

    updateScale(canvas, container.clientWidth, container.clientHeight);
    [
      MediaSDKEvent.UPDATE_CANVAS_SIZE,
      MediaSDKEvent.UPDATE_SHARING_DECODE_PARAM,
    ].forEach((e) => {
      getMediaSDK(container?.getSessionId?.())?.Notify_MeidaSDK(e, {
        width: container.clientWidth * getScale(canvas),
        height: container.clientHeight * getScale(canvas),
        canvas,
      });
    });

    canvas.style.width = container.clientWidth + 'px';
    canvas.style.height = container.clientHeight + 'px';
    try {
      canvas.width = container.clientWidth * getScale(canvas);
      canvas.height = container.clientHeight * getScale(canvas);
    } catch (e) {
      /* offscreen canvas */
    }
    store.dispatch('resize', undefined, canvas.id);
  };
  const debounceResize = debounce(handleResize, 300);
  const throttleResize = throttle(handleResize, 300);
  const resizeHandler = () => {
    const resizeStrategy =
      container?.getResizeStrategy?.() || ResizeStrategy.Debounce;
    switch (resizeStrategy) {
      case ResizeStrategy.Debounce:
        debounceResize();
        break;
      case ResizeStrategy.Throttle:
        throttleResize();
        break;
      case ResizeStrategy.None:
        handleResize();
        break;
      default:
        debounceResize();
        break;
    }
  };
  const dprQuery = matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`);
  const resizeObserver = new ResizeObserver(resizeHandler);
  dprQuery.addEventListener('change', resizeHandler);
  resizeObserver.observe(container);
  return () => {
    removeScale(canvas);
    resizeObserver?.disconnect();
    dprQuery.removeEventListener('change', resizeHandler);
  };
}

export function defineCustomAttributes(el: HTMLElement, attrList: string[]) {
  Object.defineProperties(
    el,
    attrList.reduce<PropertyDescriptorMap>((res, attributeKey: string) => {
      res[attributeKey] = {
        get: () => {
          return el.getAttribute(attributeKey);
        },
        set: (newVal: string | null) => {
          if (newVal) {
            el.setAttribute(attributeKey, newVal);
          } else {
            el.removeAttribute(attributeKey);
          }
        },
      };
      return res;
    }, {})
  );
}

export function initDefaultValues(
  el: HTMLElement,
  defaultValues: Record<string, string>
) {
  Object.entries(defaultValues).forEach(([attr, value]) => {
    if (!el.getAttribute(attr)) {
      el.setAttribute(attr, value);
    }
  });
}

export function parseBoolAttr(el: HTMLElement, key: string) {
  const val = el.getAttribute(key);
  if (val && val === 'true') {
    return true;
  }
  return false;
}

export function getMediaSDK(sessionId: string = DEFAULT_SESSION_KEY) {
  return store.get('mediaSDKInstanceMap').get(sessionId)?.();
}

export function randomUUID() {
  if (typeof crypto.randomUUID === 'function') {
    return crypto.randomUUID();
  }
  // Public Domain/MIT
  let d = new Date().getTime(); // Timestamp
  // Time in microseconds since page-load or 0 if unsupported
  let d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

type AnyFunction = (...args: any[]) => void;

export function debounce(func: AnyFunction, delay: number): AnyFunction {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (...args: any[]) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export function throttle(func: AnyFunction, delay: number): AnyFunction {
  let lastExecTime = 0;
  let timeoutId: ReturnType<typeof setTimeout> | null;

  return function (...args: any[]) {
    const currentTime = Date.now();

    const timeSinceLastExec = currentTime - lastExecTime;

    if (!lastExecTime || timeSinceLastExec >= delay) {
      func(...args);
      lastExecTime = currentTime;
    } else if (!timeoutId) {
      timeoutId = setTimeout(() => {
        func(...args);
        lastExecTime = Date.now();
        timeoutId = null;
      }, delay - timeSinceLastExec);
    }
  };
}

export function objectsAreEqual(
  obj1: Record<string, any>,
  obj2: Record<string, any>
): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!objectsAreEqual(val1, val2)) {
        return false;
      }
    } else {
      if (val1 !== val2) {
        return false;
      }
    }
  }

  return true;
}

export function isEmptyValue(s: string | null): boolean {
  return !s || s === '0';
}

const scaleMap = new Map();

export function updateScale(
  canvas: HTMLCanvasElement,
  width: number,
  height: number
) {
  const scale =
    width >= window.screen.width * 2 || height >= window.screen.height * 2
      ? 1
      : window.devicePixelRatio;
  const oldScale = scaleMap.get(canvas);
  scaleMap.set(canvas, scale);
  if (oldScale && oldScale !== scale) {
    store.dispatch('scale', undefined, canvas.id);
  }
  return scale;
}

export function removeScale(canvas: HTMLCanvasElement) {
  scaleMap.delete(canvas);
}

export function getScale(canvas: HTMLCanvasElement) {
  return scaleMap.get(canvas) || window.devicePixelRatio;
}

export function addLog(mediaSDK?: MediaSDK, s?: string) {
  s && mediaSDK?.Notify_MeidaSDK(MediaSDKEvent.SEND_RENDER_LOG, { message: s });
}

export function updateCurRenderNum(
  container: VideoPlayerContainer,
  add: boolean
) {
  const renderCountMap = store.get('renderCountMap');
  const prev = renderCountMap.get(container) || 0;
  renderCountMap.set(container, add ? prev + 1 : prev - 1);
}

export function checkRenderNum(container: VideoPlayerContainer) {
  const renderCountMap = store.get('renderCountMap');
  const curRenderNum = renderCountMap.get(container) || 0;
  const maxRenderNum = JsMediaSDK_Instance?.util?.getMaxCountRender?.() || 25;
  const isValid = curRenderNum >= 0 && curRenderNum <= maxRenderNum;
  const tagName = store.get('tagName');
  if (!isValid) {
    console.error(
      `In your environment, the maximum number of ${tagName} that can be accommodated in each ${tagName}-container is ${maxRenderNum}. The current number has exceeded this limit, which may cause video rendering issues.`
    );
  }
}
