import {
  PreviewVideoRender,
  SharingRender,
  VBCanvasRender,
  VBVideoRender,
  WasmVideoRender,
  WebRTCVideoRender,
} from './render';
import { Render } from './render/base';
import {
  InsideCanvasRender,
  InsideVideoTagRender,
} from './render/inside-render';
import { store } from './store';
import {
  RenderParams,
  MediaType,
  DEFAULT_SESSION_KEY,
  MediaSDKNotify,
  InsideRenderType,
} from './types';
import {
  addLog,
  defineCustomAttributes,
  getMediaSDK,
  initDefaultValues,
  isEmptyValue,
  parseBoolAttr,
} from './utils';
import { VideoPlayerContainer } from './video-player-container';

const NODE_ID = 'node-id';
const MEDIA_TYPE = 'media-type';
const VIDEO_QUALITY = 'video-quality';
const SHARE_SOURCE = 'share-source';
const FILL_MODE = 'fill-mode';
const STOP_NO_CLEAN = 'stop-no-clean';
const REFRESH_KEY = 'refresh-key';

export class VideoPlayer extends HTMLElement {
  static customAttributes = [
    NODE_ID,
    MEDIA_TYPE,
    VIDEO_QUALITY,
    SHARE_SOURCE,
    FILL_MODE,
    STOP_NO_CLEAN,
    REFRESH_KEY,
  ];
  static defaultValues: Record<string, string> = {
    [NODE_ID]: '',
    [MEDIA_TYPE]: MediaType.Video,
  };

  static get observedAttributes() {
    return [NODE_ID, MEDIA_TYPE, VIDEO_QUALITY, REFRESH_KEY];
  }

  private container: VideoPlayerContainer | null = null;

  private render?: Render;

  private getRenderParams: () => RenderParams;

  private removeSubscribe?: () => void;

  private callback: (type: MediaSDKNotify, data: any) => void;
  private _srcObject: MediaProvider | null = null;

  constructor() {
    super();

    defineCustomAttributes(this, VideoPlayer.customAttributes);

    this.getRenderParams = this.getParsedParams.bind(this);

    this.callback = (type: MediaSDKNotify, data: any) => {
      // for ios preview with video tag, need to reload element.
      if (
        type === MediaSDKNotify.MOBILE_CAPTURE_DEVICE_CHANGE &&
        data === this.getAttribute(NODE_ID)
      ) {
        this.initRender();
      }
    };
    // this.attachShadow({ mode: 'open' });
  }

  initRender() {
    if (this.render) {
      this.render.destroy();
    }

    if (this.getContainer()?.useInsideRender()) {
      const renderType = this.getContainer()?.getInsideRenderType();
      if (renderType === InsideRenderType.VideoTag) {
        this.render = new InsideVideoTagRender(this.getContainer());
      } else if (
        [
          InsideRenderType.WebGL,
          InsideRenderType.WebGL2,
          InsideRenderType.WebGPU,
        ].includes(renderType!)
      ) {
        this.render = new InsideCanvasRender(
          this.getContainer(),
          this.getRenderParams
        );
      } else {
        console.error('Inside render type error.');
        return;
      }

      if (this.shadowRoot) {
        this.shadowRoot.innerHTML = '';
        this.shadowRoot.appendChild(this.render.getElement());
      }

      this.render.init();

      if (this.srcObject) {
        this.render.playVideo(this.srcObject);
      }

      return;
    }

    const nodeId = this.getAttribute(NODE_ID);
    const previewVideotag =
      nodeId && this.getCurrentMediaSDK()?.isPreviewVideotag(nodeId);
    const mediaType = this.getAttribute(MEDIA_TYPE);
    let type = 0;
    if (mediaType === MediaType.Preview) {
      if (!this.getCurrentMediaSDK()?.getWebRTCFlag()) {
        this.render = new VBCanvasRender(null);
        type = 5;
      } else {
        this.render = new VBVideoRender(null);
        type = 6;
      }
    } else if (mediaType === MediaType.Share) {
      this.render = new SharingRender(
        this.getContainer(),
        this.getRenderParams
      );
      type = 2;
    } else {
      if (!this.getCurrentMediaSDK()?.getWebRTCFlag()) {
        if (previewVideotag) {
          this.render = new PreviewVideoRender(this.getContainer());
          type = 1;
        } else {
          this.render = new WasmVideoRender(
            this.getContainer(),
            this.getRenderParams
          );
          type = 3;
        }
      } else {
        this.render = new WebRTCVideoRender(this.getContainer());
        type = 4;
      }
    }

    if (this.getCurrentMediaSDK()) {
      addLog(this.getCurrentMediaSDK()!, `VPREN:${type}`);
    }

    this.innerHTML = '';
    this.appendChild(this.render.getElement());
    // if (this.shadowRoot) {
    //   this.shadowRoot.innerHTML = '';
    //   this.shadowRoot.appendChild(this.render.getElement());
    // }

    this.render.init();

    const id = this.getAttribute(NODE_ID);
    if (!isEmptyValue(id)) {
      this.render?.playVideo(id!);
    }
  }

  get srcObject(): MediaProvider | null {
    return this._srcObject;
  }

  set srcObject(value: MediaProvider | null) {
    if (!this._srcObject && value) {
      this.render?.playVideo(value);
    } else if (this._srcObject && value) {
      this.render?.stopVideo();
      this.render?.playVideo(value);
    } else {
      if (this._srcObject) {
        this.render?.stopVideo();
      }
    }
    this._srcObject = value;
  }

  getContainer() {
    if (!this.container) {
      this.container = this.closest<VideoPlayerContainer>(
        `${store.get('tagName')}-container`
      );
    }
    return this.container;
  }

  getCurrentMediaSDK() {
    return getMediaSDK(this.getContainer()?.getSessionId?.());
  }

  getParsedParams(): RenderParams {
    return {
      fillMode: parseBoolAttr(this, FILL_MODE),
      doNotClean: parseBoolAttr(this, STOP_NO_CLEAN),
      isFromMainSession: this.getAttribute(SHARE_SOURCE) === 'main',
      videoQuality: this.getAttribute(VIDEO_QUALITY),
      renderType:
        this.getContainer()?.getInsideRenderType() ?? InsideRenderType.Unknown,
    };
  }

  connectedCallback() {
    if (!this.style.display) {
      this.style.display = 'block';
    }
    initDefaultValues(this, VideoPlayer.defaultValues);
    this.initRender();

    this.getCurrentMediaSDK()?.addCallback(this.callback);

    this.removeSubscribe = store.subscribe((e, state, p) => {
      if (e === 'failover') {
        if (
          p === (this.getContainer()?.getSessionId?.() || DEFAULT_SESSION_KEY)
        ) {
          this.initRender();
          if (!isEmptyValue(this.getAttribute(NODE_ID))) {
            this.render?.playVideo(this.getAttribute(NODE_ID)!);
          }
          if (this.srcObject) {
            this.render?.playVideo(this.srcObject);
          }
        }
      }
    });
  }

  disconnectedCallback() {
    this.removeSubscribe?.();
    this.render?.destroy();
    if (this.callback) {
      this.getCurrentMediaSDK()?.removeCallback(this.callback);
    }
  }

  attributeChangedCallback(name: string, oldVal: string, newVal: string) {
    if (!this.render) {
      return;
    }
    switch (name) {
      case NODE_ID:
        if (this.getCurrentMediaSDK()?.getWebRTCFlag()) {
          //
        } else {
          if (
            Boolean(this.getCurrentMediaSDK()?.isPreviewVideotag(newVal)) !==
            this.render instanceof PreviewVideoRender
          ) {
            this.initRender();
          }
        }
        if (isEmptyValue(oldVal) && !isEmptyValue(newVal)) {
          this.render.playVideo(newVal);
        } else if (!isEmptyValue(oldVal) && !isEmptyValue(newVal)) {
          this.render.stopVideo();
          this.render.playVideo(newVal);
        } else {
          if (!isEmptyValue(oldVal)) {
            this.render.stopVideo();
          }
        }
        break;
      case MEDIA_TYPE:
        this.initRender();
        break;
      case VIDEO_QUALITY:
        this.render?.updateVideoQuality(newVal);
        break;
      case REFRESH_KEY:
        if (!isEmptyValue(newVal)) {
          this.render?.refresh?.();
        }
        break;
      default:
        break;
    }
  }
}
