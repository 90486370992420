import { VideoAddRenderVideo, VideoStopRenderVideo } from '../types';
import { getMediaSDK, randomUUID } from '../utils';
import { VideoPlayerContainer } from '../video-player-container';
import { Render } from './base';
import { store } from '../store';

export abstract class VideoRender implements Render {
  private el: HTMLVideoElement;
  private id: string = '';
  private srcObject: MediaProvider | null = null;
  private viewId = `${store.get('tagName')}-${randomUUID()}`;

  protected getSDK = () => getMediaSDK(this.container?.getSessionId?.());

  constructor(private container: VideoPlayerContainer | null) {
    this.el = this.createVideoElement();
  }

  createVideoElement() {
    const el = document.createElement('video');
    el.style.width = '100%';
    el.style.height = '100%';
    el.style.borderRadius = 'inherit';
    el.setAttribute('autoplay', 'true');
    el.setAttribute('muted', 'true');
    // video tag created by JavaScript need set muted in js to enable autoplay
    el.muted = true;
    el.setAttribute('playsinline', 'true');
    return el;
  }

  replaceVideoElement(el: HTMLVideoElement) {
    const newEl = this.createVideoElement();
    el.replaceWith(newEl);
    setTimeout(() => {
      el.srcObject = null;
    }, 50);
    this.el = newEl;
  }

  getElement(): HTMLVideoElement {
    return this.el;
  }

  init() {}

  public playVideo(source: string | MediaProvider) {
    if (typeof source === 'string') {
      this.id = source;
    } else {
      this.srcObject = source;
    }
    this.addRender({
      id: this.id,
      zone: this.viewId,
      userId: Number(this.id),
      videodom: this.el,
      ...(!this.srcObject ? {} : { srcObject: this.srcObject }),
    });
  }

  public stopVideo() {
    if (!this.id && !this.srcObject) return;
    this.stopRender({
      id: this.id,
      zone: this.viewId,
      userId: Number(this.id),
      videodom: this.el,
      ...(!this.srcObject ? {} : { srcObject: this.srcObject }),
    });
    if (this.getSDK()?.getWebRTCFlag()) {
      this.replaceVideoElement(this.el);
    }
    this.id = '';
    this.srcObject = null;
  }

  public updateVideoQuality(quality: string): void {
    return;
  }

  public destroy() {
    this.stopVideo();
  }

  protected abstract addRender(p: VideoAddRenderVideo): void;
  protected abstract stopRender(p: VideoStopRenderVideo): void;
}
