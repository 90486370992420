import { store } from './store';
import {
  DEFAULT_SESSION_KEY,
  MediaSDKEvent,
  MediaSDKNotify,
  WebGLLostInMultiView,
  WebGLLostReplaceCanvas,
  RemoveExpiredCanvas,
  ResizeStrategy,
} from './types';
import {
  addCanvasEventListener,
  addLog,
  createCanvas,
  defineCustomAttributes,
  getColorValue,
  getMediaSDK,
  initDefaultValues,
  randomUUID,
  removeScale,
} from './utils';

const SESSION_ID = 'session-id';
const Z_INDEX = 'z-index';
const INSIDE_RENDER = 'inside-render';
const INSIDE_RENDER_TYPE = 'inside-render-type';
const LABEL = 'label';
const RESIZE_STRATEGY = 'resize-strategy';

export class VideoPlayerContainer extends HTMLElement {
  static customAttributes = [];
  static defaultValues: Record<string, string> = {};

  static get observedAttributes() {
    return [SESSION_ID, Z_INDEX, INSIDE_RENDER];
  }

  private canvasId =
    'video-player-canvas-' +
    (this.getAttribute(LABEL) ? `${this.getAttribute(LABEL)}-` : '') +
    randomUUID();

  private needInitCanvas = false;

  private canvas: HTMLCanvasElement | undefined;
  private annoCanvas: HTMLCanvasElement | undefined;

  private removeListener?: () => void;
  private removeSubscribe?: () => void;

  private callback: ((type: MediaSDKNotify, data: any) => void) | null = null;

  private appendCanvas: (canvas: HTMLCanvasElement, target?: HTMLElement) => void;

  constructor() {
    super();

    defineCustomAttributes(this, VideoPlayerContainer.customAttributes);

    const shadowRoot = this.attachShadow({ mode: 'open' });
    const slot = document.createElement('slot');
    this.appendCanvas = (canvas: HTMLCanvasElement, target = slot) => {
      shadowRoot.insertBefore(canvas, target);
    };
    shadowRoot.appendChild(slot);
  }

  connectedCallback() {
    if (!this.style.display) {
      this.style.display = 'block';
    }

    if (!this.style.position) {
      this.style.position = 'relative';
    }

    initDefaultValues(this, VideoPlayerContainer.defaultValues);

    if (this.needInitCanvas) {
      this.initCanvasEvents();
    }

    this.removeSubscribe = store.subscribe((e, state, p) => {
      if (e === 'failover') {
        if (p === (this.getSessionId() || DEFAULT_SESSION_KEY)) {
          this.replaceCanvas();
          this.initCanvasEvents();
        }
      }
    });
  }

  disconnectedCallback() {
    this.removeListener?.();
    this.removeSubscribe?.();
    if (this.canvas) {
      getMediaSDK(this.getSessionId())?.Notify_MeidaSDK<RemoveExpiredCanvas>(
        MediaSDKEvent.REMOVE_EXPIRED_CANVAS,
        { canvasId: this.canvasId }
      );
      this.canvas.remove();
      this.canvas = undefined;
    }
    if (this.callback) {
      getMediaSDK(this.getSessionId())?.removeCallback(this.callback);
    }
  }

  attributeChangedCallback(name: string, oldVal: string, newVal: string) {
    switch (name) {
      case Z_INDEX:
        if (this.canvas && newVal) {
          this.canvas.style.zIndex = newVal;
        }
        break;
      default:
        break;
    }
  }

  adoptedCallback() {}

  private createCanvas() {
    if (this.getCurrentMediaSDK()) {
      addLog(this.getCurrentMediaSDK()!, 'VPCC');
    }
    this.canvas = createCanvas(
      this.clientWidth,
      this.clientHeight,
      this.getAttribute(Z_INDEX) || '',
      this.canvasId,
      (s: string) => addLog(this.getCurrentMediaSDK()!, s)
    );
    this.appendCanvas(this.canvas);
    if (this.isConnected) {
      this.initCanvasEvents();
    } else {
      this.needInitCanvas = true;
    }
  }

  private createAnnoCanvas() {
    this.annoCanvas = createCanvas(
      this.clientWidth,
      this.clientHeight,
      this.getAttribute(Z_INDEX) || '',
      this.canvasId + '-anno',
      (s: string) => addLog(this.getCurrentMediaSDK()!, s),
      true,
    );
    this.appendCanvas(this.annoCanvas);
  }

  private initCanvasEvents() {
    this.removeListener = addCanvasEventListener(this.canvas!, this);

    this.callback = (type, data: WebGLLostInMultiView) => {
      if (type === MediaSDKNotify.WEBGL_LOST_IN_MULTI_VIEW) {
        if (data.replaceCanvas && data.canvasId === this.canvas?.id) {
          this.replaceCanvas();
          getMediaSDK(
            this.getSessionId()
          )?.Notify_MeidaSDK<WebGLLostReplaceCanvas>(
            MediaSDKEvent.WEBGL_LOST_REPLACE_CANVAS,
            { canvasId: this.canvasId, canvas: this.canvas }
          );
        }
      }
    };

    this.getCurrentMediaSDK()?.addCallback(this.callback);
  }

  private replaceCanvas() {
    if (this.getCurrentMediaSDK()) {
      addLog(this.getCurrentMediaSDK()!, 'VPRC');
    }
    if (this.canvas) {
      this.removeListener?.();
      const newCanvas = createCanvas(
        this.clientWidth,
        this.clientHeight,
        this.getAttribute(Z_INDEX) || '',
        this.canvasId,
        (s: string) => addLog(this.getCurrentMediaSDK()!, s)
      );
      this.removeListener = addCanvasEventListener(newCanvas, this);
      if (this.shadowRoot) {
        this.shadowRoot.replaceChild(newCanvas, this.canvas);
      }
      this.canvas = newCanvas;
    }
    if (this.annoCanvas) {
      const newAnnoCanvas = createCanvas(
        this.clientWidth,
        this.clientHeight,
        this.getAttribute(Z_INDEX) || '',
        this.canvasId + 'anno',
        (s: string) => addLog(this.getCurrentMediaSDK()!, s)
      );
      if (this.shadowRoot) {
        this.shadowRoot.replaceChild(newAnnoCanvas, this.annoCanvas);
      }
      this.annoCanvas = newAnnoCanvas;
    }
  }

  public getResizeStrategy() {
    return this.getAttribute(RESIZE_STRATEGY) || ResizeStrategy.Debounce;
  }

  public getCanvas() {
    if (!this.canvas) {
      this.createCanvas();
    }
    return this.canvas!;
  }

  public getAnnoCanvas() {
    if (!this.annoCanvas) {
      this.createAnnoCanvas();
    }
    return this.annoCanvas!;
  }

  public useInsideRender() {
    return (
      this.getAttribute(INSIDE_RENDER) !== null &&
      this.getAttribute(INSIDE_RENDER) !== undefined &&
      this.getAttribute(INSIDE_RENDER) !== 'false' &&
      this.getAttribute(INSIDE_RENDER) !== '0'
    );
  }

  public getInsideRenderType() {
    return Number(this.getAttribute(INSIDE_RENDER_TYPE));
  }

  public getSessionId() {
    return this.getAttribute(SESSION_ID) || undefined;
  }

  public getCurrentMediaSDK() {
    return getMediaSDK(this.getSessionId());
  }

  public getRGBA() {
    return getColorValue(
      getComputedStyle(this).getPropertyValue('background-color')
    );
  }
}
