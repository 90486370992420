class WebGPURenderer {
  // Generates two triangles covering the whole canvas.
  static VERTEX_SHADER = /* wgsl */ `
    struct VertexOutput {
      @builtin(position) Position: vec4<f32>,
      @location(0) uv: vec2<f32>,
    }

    @vertex
    fn vert_main(@builtin(vertex_index) VertexIndex: u32) -> VertexOutput {
      var pos = array<vec2<f32>, 6>(
        vec2<f32>( 1.0,  1.0),
        vec2<f32>( 1.0, -1.0),
        vec2<f32>(-1.0, -1.0),
        vec2<f32>( 1.0,  1.0),
        vec2<f32>(-1.0, -1.0),
        vec2<f32>(-1.0,  1.0)
      );

      var uv = array<vec2<f32>, 6>(
        vec2<f32>(1.0, 0.0),
        vec2<f32>(1.0, 1.0),
        vec2<f32>(0.0, 1.0),
        vec2<f32>(1.0, 0.0),
        vec2<f32>(0.0, 1.0),
        vec2<f32>(0.0, 0.0)
      );

      var output : VertexOutput;
      output.Position = vec4<f32>(pos[VertexIndex], 0.0, 1.0);
      output.uv = uv[VertexIndex];
      return output;
    }
  `;

  // Samples the external texture using generated UVs.
  static FRAG_SHADER_VF = /* wgsl */ `
    @group(0) @binding(0) var mySampler: sampler;
    @group(0) @binding(1) var vfTexture: texture_external;

    @fragment
    fn frag_main(@location(0) uv : vec2<f32>) -> @location(0) vec4<f32> {
      var color0: vec4<f32> = textureSampleBaseClampToEdge(vfTexture, mySampler, uv);
      return color0;
    }
  `;

  constructor() {}

  async preview(source, target, viewport) {
    if (!source || !target || !viewport) {
      throw new Error(
        'Invalid arguments: source, target, or viewport is missing'
      );
    }

    const adapter = await navigator.gpu.requestAdapter();
    const device = await adapter.requestDevice();
    const format = navigator.gpu.getPreferredCanvasFormat();

    const ctx = target.getContext('webgpu');
    ctx.configure({
      device: device,
      format: format,
      alphaMode: 'opaque',
    });

    const sampler = device.createSampler({});
    const pipeline = device.createRenderPipeline({
      layout: 'auto',
      vertex: {
        module: device.createShaderModule({
          code: WebGPURenderer.VERTEX_SHADER,
        }),
        entryPoint: 'vert_main',
      },
      fragment: {
        module: device.createShaderModule({
          code: WebGPURenderer.FRAG_SHADER_VF,
        }),
        entryPoint: 'frag_main',
        targets: [
          {
            format: format,
          },
        ],
      },
      primitive: {
        topology: 'triangle-list',
      },
    });

    const resBundle = {
      device: device,
      context: ctx,
      pipeline: pipeline,
      sampler: sampler,
      source: source,
      viewport: viewport,
    };

    this.frame(resBundle);
  }

  frame(resBundle) {
    const commandEncoder = resBundle.device.createCommandEncoder();
    const textureView = resBundle.context.getCurrentTexture().createView();
    const renderPassDescriptor = {
      colorAttachments: [
        {
          view: textureView,
          loadOp: 'clear',
          storeOp: 'store',
        },
      ],
    };

    const passEncoder = commandEncoder.beginRenderPass(renderPassDescriptor);
    passEncoder.setPipeline(resBundle.pipeline);

    const uniformBindGroup = resBundle.device.createBindGroup({
      layout: resBundle.pipeline.getBindGroupLayout(0),
      entries: [
        { binding: 0, resource: resBundle.sampler },
        {
          binding: 1,
          resource: resBundle.device.importExternalTexture({
            source: resBundle.source,
          }),
        },
      ],
    });

    passEncoder.setBindGroup(0, uniformBindGroup);
    passEncoder.setViewport(
      resBundle.viewport.x,
      resBundle.viewport.y,
      resBundle.viewport.w,
      resBundle.viewport.h,
      0,
      1
    );

    passEncoder.draw(6);
    passEncoder.end();
    resBundle.device.queue.submit([commandEncoder.finish()]);

    requestAnimationFrame(() => this.frame(resBundle));
  }
}

export default WebGPURenderer;
